<template>
  <div class="main">
    <a-layout class="mlayout">
      <a-layout-header class="layhead">
        <top-bar msg="" showback="0" />
      </a-layout-header>
      <a-layout>
        <sider-bar />
        <a-layout-content class="adminalcontent">
          <div class="maindiv">
            <a-breadcrumb class="bread">
              <a-breadcrumb-item>
                <router-link to="/index"> 控制台 </router-link>
              </a-breadcrumb-item>
              <a-breadcrumb-item> 前端用户管理 </a-breadcrumb-item>
            </a-breadcrumb>

            <div class="searchbar">
              <a-row>
                <a-col :span="2" class="title"> 查询条件 </a-col>
                <a-col :span="18">
                  <a-input-search
                    placeholder="关键字搜索"
                    v-model="query.searchkey"
                    enter-button
                    style="width: 200px"
                    @search="onSearch"
                  />
                </a-col>
                <a-col :span="4">
                  <a-button
                    v-if="thisrule > 1 && logininfo.ksa_level < 3"
                    type="primary"
                    @click="
                      () => {
                        outExcel();
                      }
                    "
                    style="margin-right: 10px"
                  >
                    <a-icon type="plus" /> 导出 EXCEL
                  </a-button>
                </a-col>
              </a-row>
            </div>

            <a-row>
              <a-col :span="8" class="tabletools">
                <!-- <a-button
                  v-if="thisrule > 1"
                  type="primary"
                  @click="add"
                  style="margin-right: 10px"
                >
                  <a-icon type="plus" /> 添加
                </a-button> -->
                <span style="margin-left: 20px"
                  >共（<b>{{ pagination.total }}</b
                  >）条</span
                >
              </a-col>
              <a-col :span="4"> </a-col>
              <a-col :span="4"> </a-col>
            </a-row>

            <a-table
              size="small"
              class="mtable"
              :loading="loading"
              :pagination="pagination"
              :columns="columns"
              :data-source="data"
              @change="handleTableChange"
            >
              <span
                slot="indexText"
                class="nmitem"
                slot-scope="text, record, index"
                >{{ index + 1 }}</span
              >
              <span slot="normalText" class="nmitem" slot-scope="text">{{
                text
              }}</span>

              <span slot="codeText" class="nmitem" slot-scope="text, record"
                >{{ text }}
                <template v-if="record.luhu != ''">
                  <span v-if="record.luhu == '1'">(路虎车主)</span>
                </template>
              </span>

              <span slot="nameText" class="nmitem" slot-scope="text, record">
                {{ record.username }}
                <template v-if="record.nickName != ''">
                  <p
                    style="
                      margin-left: 0px;
                      font-size: 14px;
                      margin-bottom: 0px;
                    "
                  >
                    <b>昵称: {{ record.nickName }}&nbsp;&nbsp;</b>
                    <br />
                    <span style="color: #999"
                      >(OPENID:{{ record.openid }})</span
                    >
                  </p>
                  <p
                    style="
                      margin-left: 0px;
                      font-size: 14px;
                      margin-bottom: 0px;
                    "
                  >
                    <b>姓名: {{ record.userName }}&nbsp;&nbsp;</b>
                    <span style="color: #999">(ID:{{ record.idNumber }})</span>
                  </p>
                  <p
                    style="
                      margin-left: 0px;
                      font-size: 14px;
                      margin-bottom: 0px;
                    "
                  >
                    <b>电话: {{ record.phoneno }}&nbsp;&nbsp;</b>
                  </p>
                </template>
              </span>
              <span slot="vipText" class="nmitem" slot-scope="text, record">
                <template v-if="text == '1'">
                  <b style="color: #ff0000">过期</b>
                </template>
                <template v-if="text == '0'">
                  <b style="color: green">{{ record.finaltime }} 到期</b>
                </template>
              </span>

              <span slot="class2sText" class="nmitem" slot-scope="text, record">
                <p
                  v-for="item in record.class2s"
                  :key="item.no"
                  style="margin-left: 0px; font-size: 14px; margin-bottom: 0px"
                >
                  <b>{{ item.cname }}</b
                  >&nbsp;{{ item.code }}<a-divider type="vertical" />
                </p>
              </span>
              <span slot="photoarea" class="photo" slot-scope="text">
                <img :src="baseUrl + text" v-if="text != ''" />
                <img v-else src="../assets/user.png" />
              </span>

              <b
                slot="blodText"
                class="nmitem"
                slot-scope="text"
                style="color: #000; font-size: 16px"
                >{{ text }}</b
              >

              <span slot="action" slot-scope="text, record">
                <template v-if="thisrule == 3">
                  <!-- <a @click="onPassword(record)">修改密码</a> -->
                  <!-- <template v-if="record.level > 0"> -->
                  <!-- <a-divider type="vertical" /> -->
                  <a @click="onShow(record)">查看</a>
                  <!-- </template> -->
                </template>
              </span>
            </a-table>

            <a-modal
              :title="modaltitle"
              :visible="visibleAddModal"
              :confirm-loading="confirmLoading"
              ok-text="确认"
              cancel-text="取消"
              @ok="handleAddOk"
              @cancel="handleAddCancel"
              :width="800"
            >
              <a-form :form="form" class="myform">
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="照片"
                >
                  <img
                    v-if="showrecord.photo != ''"
                    :src="baseUrl + showrecord.photo"
                    style="height: 120px"
                  />
                  <img v-else src="../assets/user.png" style="height: 120px" />
                  <!-- <a-input hidden v-decorator="['photourl']" placeholder="" />
                  <a-upload
                    action=""
                    list-type="picture-card"
                    :show-upload-list="false"
                    :before-upload="
                      (file) => {
                        beforeImgUpload(file);
                      }
                    "
                  >
                    <img
                      v-if="imageUrl"
                      class="tmimage"
                      :src="imageUrl"
                      alt="avatar"
                    />
                    <div v-else>
                      <a-icon :type="loading ? 'loading' : 'plus'" />
                      <div class="ant-upload-text">上传图片</div>
                    </div>
                  </a-upload> -->
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="昵称"
                >
                  {{ showrecord.nickName }}
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="姓名"
                >
                  {{ showrecord.name }}
                  <!-- <a-input
                    style="width: 300px"
                    v-decorator="[
                      'username',
                      {
                        rules: [{ required: true, message: '请填写用户名' }],
                      },
                    ]"
                    oninput="if(value.length > 20)value = value.slice(0, 20)"
                    placeholder=""
                  /> -->
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="称谓"
                >
                  {{ showrecord.sex }}
                </a-form-item>
                <a-form-item
                  v-if="passwordshow"
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="称谓"
                >
                  {{ showrecord.sex }}
                  <!-- <a-input-password
                    style="width: 300px"
                    v-decorator="[
                      'password',
                      { rules: [{ required: true, message: '请设置密码' }] },
                    ]"
                    oninput="if(value.length > 32)value = value.slice(0, 32)"
                    placeholder=""
                  /> -->
                </a-form-item>

                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="手机号码"
                >
                  {{ showrecord.tel }}
                  <!-- <a-input
                    style="width: 300px"
                    v-decorator="[
                      'phone',
                      {
                        rules: [{ required: true, message: '请填写手机号码' }],
                      },
                    ]"
                    oninput="if(value.length > 11)value = value.slice(0, 11)"
                    placeholder=""
                  />
                  (手机短信验证登录) -->
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="birthday"
                >
                  {{ showrecord.birthday }}
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="country"
                >
                  {{ showrecord.country }}
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="PC"
                >
                  {{ showrecord.PC }}
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="证件类型"
                >
                  {{ showrecord.idtype }}
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="证件号码"
                >
                  {{ showrecord.idnumber }}
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="行业"
                >
                  {{ showrecord.industry }}
                </a-form-item>
                <!-- <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="公司"
                >
                  {{ showrecord.company }}
                </a-form-item> -->
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="职务"
                >
                  {{ showrecord.duties }}
                </a-form-item>

                <!-- <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="年薪"
                >
                  {{ showrecord.as }} 元
                </a-form-item> -->
                <!-- <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="户外年限"
                >
                  {{ showrecord.years }}
                </a-form-item> -->

                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="感兴趣的户外板块"
                  v-if="showrecord.like0"
                >
                  <div
                    style="display: flex; flex-direction: row; flex-wrap: wrap"
                  >
                    <template
                      v-for="(item, index) in showrecord.like0.split(',')"
                    >
                      <div class="likeitem" :key="index" v-html="item"></div>
                    </template>
                  </div>
                  <div v-if="showrecord.other0 != ''">
                    其他:{{ showrecord.other0 }}
                  </div>
                </a-form-item>
                <!-- <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="最常通过何种平台了解户外内容？"
                  v-if="showrecord.like1"
                >
                  <template
                    v-for="(item, index) in showrecord.like1.split(',')"
                  >
                    <span class="likeitem" :key="index" v-html="item"></span>
                  </template>
                </a-form-item> -->
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="对何种形式的线下活动感兴趣？"
                  v-if="showrecord.like2"
                >
                  <div
                    style="display: flex; flex-direction: row; flex-wrap: wrap"
                  >
                    <template
                      v-for="(item, index) in showrecord.like2.split(',')"
                    >
                      <div class="likeitem" :key="index" v-html="item"></div>
                    </template>
                  </div>
                  <div v-if="showrecord.other2 != ''">
                    其他:{{ showrecord.other2 }}
                  </div>
                </a-form-item>

                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="期望通过ALL FIELDS获得何种收获？"
                  v-if="showrecord.like4"
                >
                  <div
                    style="display: flex; flex-direction: row; flex-wrap: wrap"
                  >
                    <template
                      v-for="(item, index) in showrecord.like4.split(',')"
                    >
                      <div class="likeitem" :key="index" v-html="item"></div>
                    </template>
                  </div>
                  <div v-if="showrecord.other4 != ''">
                    其他:{{ showrecord.other4 }}
                  </div>
                </a-form-item>

                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="通过何种途径了解到ALL FIELDS？"
                  v-if="showrecord.like3"
                >
                  <div
                    style="display: flex; flex-direction: row; flex-wrap: wrap"
                  >
                    <template
                      v-for="(item, index) in showrecord.like3.split(',')"
                    >
                      <div class="likeitem" :key="index" v-html="item"></div>
                    </template>
                  </div>
                  <div v-if="showrecord.other3 != ''">
                    其他:{{ showrecord.other3 }}
                  </div>
                </a-form-item>
                <!-- <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="每年在户外相关的投入？"
                  v-if="showrecord.like5"
                >
                  <template
                    v-for="(item, index) in showrecord.like5.split(',')"
                  >
                    <span class="likeitem" :key="index" v-html="item"></span>
                  </template>
                </a-form-item> -->
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="愿意跟谁一起体验户外生活？"
                  v-if="showrecord.like6"
                >
                  <div
                    style="display: flex; flex-direction: row; flex-wrap: wrap"
                  >
                    <template
                      v-for="(item, index) in showrecord.like6.split(',')"
                    >
                      <div class="likeitem" :key="index" v-html="item"></div>
                    </template>
                  </div>
                  <div v-if="showrecord.other6 != ''">
                    其他:{{ showrecord.other6 }}
                  </div>
                </a-form-item>

                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="锁定"
                >
                  <a-input hidden v-decorator="['id']" placeholder="" />
                  <a-checkbox
                    v-decorator="['lock', { valuePropName: 'checked' }]"
                  >
                  </a-checkbox>
                </a-form-item>

                <!-- <a-form-item
                  v-if="this.form.getFieldValue('level') > 3"
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="作业项目"
                >
                </a-form-item> -->
              </a-form>
            </a-modal>
          </div>
          <a-layout-footer class="layfoot">
            <footer-bar />
          </a-layout-footer>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
const countries = require("../assets/json/countries.json");
const states = require("../assets/json/states.json");
import { fetchMembers, updateMemberlock } from "../api/index";
// const Base64 = require('js-base64').Base64
import global from "../common";
import TopBar from "../components/TopBar.vue";
import SiderBar from "../components/Sider.vue";
import FooterBar from "../components/FooterBar.vue";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

const columns = [
  {
    title: "序号",
    dataIndex: "id",
    width: 60,
    scopedSlots: { customRender: "indexText" },
  },
  {
    title: "头像",
    dataIndex: "photo",
    align: "center",
    width: 140,
    scopedSlots: { customRender: "photoarea" },
  },
  {
    title: "称谓",
    dataIndex: "sex",
    scopedSlots: { customRender: "normalText" },
  },
  {
    title: "姓名",
    dataIndex: "name",
    scopedSlots: { customRender: "normalText" },
  },
  {
    title: "电话",
    dataIndex: "tel",
    scopedSlots: { customRender: "normalText" },
  },
  {
    title: "证件",
    dataIndex: "idtype",
    scopedSlots: { customRender: "normalText" },
  },
  {
    title: "证件号",
    dataIndex: "idnumber",
    scopedSlots: { customRender: "normalText" },
  },
  {
    title: "来源(代码)",
    dataIndex: "code",
    scopedSlots: { customRender: "codeText" },
  },
  {
    title: "添加时间",
    dataIndex: "addtime",
    scopedSlots: { customRender: "normalText" },
  },
  {
    title: "操作",
    key: "action",
    width: 160,
    scopedSlots: { customRender: "action" },
  },
];

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 10, offset: 6 },
};

export default {
  name: "teammag",
  components: {
    TopBar,
    FooterBar,
    SiderBar,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      countries: countries.RECORDS,
      states: states.RECORDS,
      citycode: "",
      cityData: [],
      unitlist: [],
      unitlistf: [],
      unid: "",
      visiblePasswordModal: false,

      siderdata: [],

      imageUrl: "",
      uploading: false,

      modaltitle: "添加",
      data: [],
      columns,
      pagination: {},
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,

      tableData: [],
      pageTotal: 0,
      pageSize: 50,
      page: 1,

      visibleAddModal: false,
      confirmLoading: false,

      form2: this.$form.createForm(this, { name: "Password" }),

      form: this.$form.createForm(this, { name: "Team" }),
      formItemLayout,
      formTailLayout,
      action: "add",
      editid: 0,
      classlist: [],
      classlist2: [],
      class1: 0,
      class2: 0,

      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 20, offset: 0 },
          sm: { span: 20, offset: 4 },
        },
      },

      password: "",

      passwordshow: true,

      thisrule: 0,

      query: {
        searchkey: "",
        citycode: "",
        level: "",
        city: "",
        unid: "",
      },

      logininfo: [],

      provinces: [],
      citycode: "",
      cityname: "",
      citys: [],
      discode: "",
      disname: "",
      diss: [],

      treevalue: [],

      level: 1,

      baseUrl: "",

      showrecord: {},
    };
  },
  mounted() {
    this.baseUrl = global.baseUrl;
    this.fetch();
    this.thisrule = global.checkrule();
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  created() {
    // this.setsiderdata();
    this.logininfo = global.getLoginA();
    console.log("logininfo:", this.logininfo);
  },
  methods: {
    outExcel() {
      window.open("/api/table/outexcel_memberslist.php");
    },

    onSearch(e) {
      console.log(e);
      // this.query.searchkey = e;
      let that = this;

      console.log(that.query);

      this.resetpage();

      that.fetch();
    },

    resetpage() {
      this.page = 1;
      const pagination = { ...this.pagination };
      pagination.current = 1;
      this.pagination = pagination;
    },

    deleteAll() {
      const ids = this.selectedRowKeys.join(",");
      console.log("del:", ids);
      const that = this;
      this.$confirm({
        title: "提示",
        content: "确定删除这条题目?",
        okText: "删除",
        cancelText: "取消",
        onOk() {
          delUser({ id: ids }).then((res) => {
            console.log(
              "%cdel",
              "color: #FF0000;font-size: 18px;font-weight: bold;",
              res
            );
            that.fetch();
          });
        },
        onCancel() {},
      });
    },

    handleAddOk() {
      this.confirmLoading = true;
      const that = this;

      this.form.validateFields((err, values) => {
        console.log("Received values of form: ", values);

        if (!err) {
          console.info("success");

          let postdata = {
            id: values.id,
            lock: values.lock == true ? "1" : "0",
          };

          console.log("postdata:", postdata);

          // return false;

          updateMemberlock(postdata).then((res) => {
            console.log(res);
            let results = res.results;
            that.confirmLoading = false;
            that.visibleAddModal = false;
            that.fetch();
          });
        }
      });
    },

    // handleAddOk() {
    //   this.confirmLoading = true;
    //   const that = this;

    //   this.form.validateFields((err, values) => {
    //     console.log("Received values of form: ", values);

    //     if (!err) {
    //       console.info("success");

    //       let keysary = [];
    //       let rulesary = [];

    //       this.siderdata.map((item) => {
    //         keysary.push(item.key);
    //         rulesary.push(item.rule);
    //       });

    //       let lock = 0;
    //       if (values.lock == "checked") {
    //         lock = 1;
    //       }

    //       let citycode = values.citycode != undefined ? values.citycode : "";
    //       let discode = values.discode != undefined ? values.discode : "";
    //       let unid = values.unid != undefined ? values.unid : "0";
    //       let class1 = values.class1 != undefined ? values.class1 : [];

    //       let treevalue = that.treevalue;

    //       if (values.level != 4) {
    //         treevalue = [];
    //       }

    //       let postdata = {
    //         username: values.username,
    //         realname: values.realname,
    //         phone: values.phone,
    //         photourl: values.photourl,
    //         lock: lock,
    //         level: values.level,
    //         citycode: citycode,
    //         city: this.cityname,
    //         discode: discode,
    //         dis: this.disname,
    //         unid: unid,
    //         class1: class1,
    //         keys: keysary.join(","),
    //         rules: rulesary.join(","),
    //         class2s: treevalue,
    //       };

    //       console.log("postdata:", postdata);

    //       return false;

    //       if (this.action == "add") {
    //         postdata.password = values.password;
    //         addUser(postdata).then((res) => {
    //           console.log(res);
    //           let results = res.results;
    //           if (results.message == "1") {
    //             that.$message.success("添加成功");
    //             that.visibleAddModal = false;
    //             that.form.resetFields();
    //             that.fetch();
    //           } else if (results.message == "2") {
    //             that.confirmLoading = false;
    //             that.$message.warning("用户名重复！");
    //             return false;
    //           }
    //           that.confirmLoading = false;
    //           that.visibleAddModal = false;
    //         });
    //       } else if (this.action == "edit") {
    //         postdata.id = this.editid;
    //         updateUser(postdata).then((res) => {
    //           console.log(res);
    //           let results = res.results;
    //           if (results.message == "1") {
    //             that.$message.success("修改成功");
    //             that.visibleAddModal = false;
    //             that.form.resetFields();
    //             that.fetch();
    //           } else if (results.message == "2") {
    //             that.confirmLoading = false;
    //             that.$message.warning("用户名重复！");
    //             return false;
    //           }
    //           that.confirmLoading = false;
    //           that.visibleAddModal = false;
    //         });
    //       }
    //     } else {
    //       // that.$message.error("出现错误，请联系管理员！");
    //       // that.form.resetFields();
    //       that.confirmLoading = false;
    //     }
    //   });
    // },

    handleAddCancel() {
      this.visibleAddModal = false;
    },

    onShow(record) {
      this.modaltitle = "修改";
      this.visibleAddModal = true;
      this.passwordshow = false;
      console.log("record:", record);
      setTimeout(() => {
        this.form.resetFields();
        this.showrecord = record;

        this.form.setFieldsValue({
          lock: record.lock == "1" ? true : false,
          id: record.id,
        });

        // let keysary = record.keys.split(",");
        // let rulesary = record.rules.split(",");

        // setTimeout(() => {
        //   this.setsiderdata();
        //   let siderdata = this.siderdata;
        //   siderdata.map((item, index) => {
        //     keysary.map((item2, index2) => {
        //       if (item.key == item2) {
        //         siderdata[index].rule = rulesary[index2];
        //       }
        //     });
        //   });
        //   this.siderdata = siderdata;

        //   console.log("siderdata", this.siderdata);
        // });

        // let lock = false;
        // if (record.lock == "1") {
        //   lock = "checked";
        // }

        // this.citycode = record.citycode;

        // let class1 = record.class1 == [] ? [] : record.class1;
        // console.log("class1", class1);

        // // class1.map((item,index) => {
        // //   class1[index] = parseInt(item);
        // // });
        // // console.log("class1", class1);

        // this.level = record.level;

        // this.form.setFieldsValue({
        //   username: record.username,
        //   realname: record.realname,
        //   phone: record.phone,
        //   lock: lock,
        //   photourl: record.photourl,
        //   level: record.level,
        // });

        // setTimeout(() => {
        //   this.form.setFieldsValue({
        //     citycode: record.citycode,
        //     discode: record.discode,
        //     unid: record.unid,
        //     class1: class1,
        //   });
        // });

        // this.treevalue = record.class2s;

        // this.imageUrl = record.photourl;
        // this.fileList = record.flist;

        // console.log("fileList:", record.flist);

        // // this.setClassList(record.class1, record.class2);
        // this.action = "edit";
        // this.editid = record.id;
      });
    },
    onDel(id) {
      console.log("del:", id);
      const that = this;

      this.$confirm({
        title: "提示",
        content: "确定删除这个用户?",
        okText: "删除",
        cancelText: "取消",
        onOk() {
          delUser({ id: id }).then((res) => {
            console.log(
              "%cdel",
              "color: #FF0000;font-size: 18px;font-weight: bold;",
              res
            );
            that.fetch();
          });
        },
        onCancel() {},
      });
    },

    handleTableChange(pagination, filters, sorter) {
      console.log(pagination, filters, sorter);
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.page = pagination.current;
      this.pagination = pager;
      // this.fetch({
      //   pagesize: pagination.pageSize,
      //   page: pagination.current,
      //   sortField: sorter.field,
      //   sortOrder: sorter.order,
      //   ...filters,
      // });
      this.fetch();
    },
    fetch(
      params = { page: this.page, pagesize: this.pageSize, query: this.query }
    ) {
      let that = this;
      this.loading = true;
      // console.log(params);
      fetchMembers(params).then((res) => {
        console.log("fetchMembers:", res);
        const pagination = { ...that.pagination };
        pagination.total = parseInt(res.results.total);
        pagination.pageSize = that.pageSize;
        that.loading = false;
        that.data = res.results.list;

        for (let i = 0; i < that.data.length; i++) {
          for (var key in that.data[i]) {
            // console.log(key,this.data[i][key]);
            that.data[i][key] = global.getInpBDvalue(key, this.data[i][key]);
            if (key == "country") {
              that.countries.map((item) => {
                if (item.id == that.data[i][key]) {
                  that.data[i][key] = item.cname;
                }
              });
            }
            if (key == "PC") {
              that.states.map((item) => {
                if (item.id == that.data[i][key]) {
                  that.data[i][key] = item.cname;
                }
              });
            }
          }
        }

        console.log("this.data:", that.data);

        that.pagination = pagination;
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mtable {
  font-size: 14px;
}
.nmtitle {
  font-size: 12px;
}
.nmitem {
  font-size: 14px;
}
.tmimage {
  max-width: 200px;
  max-height: 200px;
  border: 5px solid #fff;
}
.photo img {
  height: 40px;
  width: 40px;
  border-radius: 45px;
  background-color: #fff;
  padding: 2px;
  -o-object-fit: contain;
  object-fit: cover;
}
.likeitem {
  background-color: #ef7b49;
  border: 1px solid #ef7b49 !important;
  color: #fff;
  height: auto;
  border-radius: 14px;
  border: 1px solid #dfdfdf;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 28px;
  box-sizing: border-box;
  margin-bottom: 8px;
  margin-right: 8px;
  /* padding-top: 5px;
  padding-bottom: 5px; */
}
</style>
